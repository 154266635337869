import React, { Component } from 'react';
import Scene from './Scene';
class App extends Component {
  render() {
    return (
        <Scene />
    );
  }
}

export default App;
