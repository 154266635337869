module.exports.vertexShader = `
    attribute float alpha;
    uniform float scale;
    uniform float size;
    
    varying vec2 vUv;
    varying vec3 vColor;
    
    varying float vAlpha;
    
    void main() {
    
      vUv = uv;
      vColor = color;
      
      vAlpha = alpha;
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
      gl_PointSize = size * ( scale / length( mvPosition.xyz ) );
      gl_Position = projectionMatrix * mvPosition;
    }
`;

module.exports.fragmentShader = `
uniform sampler2D map;
uniform float shift;
uniform sampler2D shape;

varying vec2 vUv;
varying vec3 vColor;
varying float vAlpha;
varying vec3 vertexNormal;

void main() {
  vec2 uv = vUv;
  uv.x += shift;
  vec4 v = texture2D(map, uv);
  if (length(v.b) > .9) discard;
  gl_FragColor = vec4(vColor, 1. );
  vec4 shapeData = texture2D( shape, gl_PointCoord );
  if(shapeData.a < .5) discard;
  gl_FragColor = gl_FragColor;
}
`;



module.exports.vertexShaderLines = `
    uniform float time;
    varying vec2 vUv;

    
    void main() {
      vUv = uv;
      vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
      gl_Position = projectionMatrix * mvPosition;
    }
`;

module.exports.fragmentShaderLines = `
uniform float time;
uniform float step;
uniform vec3 color;
varying vec2 vUv;



void main() {
  float dash = sin(vUv.x - time);
  //if(dash > 0. && step == 1.) discard;
  //if(dash < 0. && step == 2.) discard;
  
  gl_FragColor = vec4(color , 1.);
}
`;

module.exports.vertexShaderInnerGlobe = `
    uniform float time;
    varying vec2 vUv;
    varying vec3 vertexNormal;
    
    void main() {
      vertexNormal = normalize(normalMatrix * normal);
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position,1.0);
    }
`;

module.exports.fragmentShaderInnerGlobe = `
varying vec3 vertexNormal;
uniform vec3 color;

void main() {
  float intensity = pow(.5 - dot(color, vertexNormal), 2.);
  vec3 atmosphera = vec3(.3, .6, 1.) * pow(intensity, 1.);

  gl_FragColor = vec4(color + atmosphera, 1.);
}
`;

module.exports.fragmentShaderAtmosfera = `
varying vec3 vertexNormal;
uniform vec3 color;

void main() {
  float intensity = pow(.5 - dot(vertexNormal, vec3(0.,0., 1.)), 2.);
  //vec3 atmosphera = vec3(.3, .6, 1.) * pow(intensity, 1.5);

  gl_FragColor = vec4(color , 1.) * intensity;
}
`;