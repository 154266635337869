import React, { Component, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { gsap, Power4 } from "gsap";
import * as shaders from "./shaders";
import { FrontSide } from "three";

class Scene extends Component {
  constructor(props) {
    super(props);
    this.container = document.getElementById('erw_three_planet');
    this.sphereColor=this.container.dataset.sphereColor || '#6b78b5'; 
    this.prod = this.container.dataset.envProd == 'true' ? true : false; 
    this.host = this.container.dataset.host;
    this.gsapLines = [];
    this.state = {};
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.animate = this.animate.bind(this);
    this.renderScene = this.renderScene.bind(this);
    this.computeBoundingBox = this.computeBoundingBox.bind(this);
    this.setupScene = this.setupScene.bind(this);
    this.destroyContext = this.destroyContext.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.raycaster = new THREE.Raycaster();
    this.raycaster.params.Line.threshold = 0.01;
    this.mouse = new THREE.Vector2();
    this.mouseEvent = { clientX: 0, clientY: 0 };
    this.time = 0;
    this.timeLines = {value : 0};
    this.lines = [];
    this.count = { value: 0 };
    
    this.INTERSECTED = null;
    this.state = {
      showInfo : false
    }
  }
  

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowResize);
  }

  componentDidMount() {
    console.log('componentDidMount')
    fetch((!this.prod ? "/Assets/pointsData.json" : this.host +"/Assets/pointsData.json"))
      .then(response => response.json())
      .then(data => { this.pointsLines = data[this.container.dataset.lang] })
      .then(()=>{
        this.setupScene();
        this.animateLine();
      })
    
  }

  setupScene() {
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;

    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.shadowMap.enabled = false;
    renderer.outputEncoding = THREE.sRGBEncoding;
    renderer.setClearColor(0x000000, 0); // the default

    let scene = new THREE.Scene();
    scene.background = "transparent";
    let camera = new THREE.PerspectiveCamera(
      60,
      this.width / this.height,
      0.25,
      1000
    );
    camera.position.setZ(4 - this.width/this.height)
    
    scene.add(camera);

    const geom = new THREE.SphereBufferGeometry(
      1, 
      this.width > 600 ? 200 : 100,
      this.width > 600 ? 200 : 100
    );
    const color = new THREE.Color(178, 235, 242); 
    const vector = new THREE.Vector3();
    const colors = [];

    for (let i = 0; i < geom.attributes.position.count; i++) {
      color.toArray(colors, i * 3);
    }
    geom.setAttribute(
      "color",
      new THREE.BufferAttribute(new Float32Array(colors), 3)
    );
    const loader = new THREE.TextureLoader();
    loader.setCrossOrigin("");
    //const mapTexture = loader.load("/Assets/pruebas/world-big-2-grey.jpg");
    const mapTexture = loader.load(!this.prod ? "/Assets/pruebas/earthspec1k.jpg" : this.host +"/Assets/pruebas/earthspec1k.jpg");
    mapTexture.wrapS = THREE.RepeatWrapping;
    mapTexture.wrapT = THREE.RepeatWrapping;
    mapTexture.repeat.set(1, 1);
    var disk = loader.load(!this.prod ? "/Assets/pruebas/bola_negra.png" : this.host+"/Assets/pruebas/bola_negra.png");

    const material = new THREE.ShaderMaterial({
      vertexColors: THREE.VertexColors,
      uniforms: {
        map: {
          value: mapTexture,
        },
        shift: {
          value: 0,
        },
        shape: {
          value: disk,
        },
        size: {
          value: this.width > 600 ? 0.01 : 0.05,
        },
        scale: {
          value: this.height / 2,
        },
      },
      vertexShader: shaders.vertexShader,
      fragmentShader: shaders.fragmentShader,
      depthTest: true,
      transparent: true,
      alphaTest: 0.5,
      blending: THREE.NormalBlending,
      side: THREE.FrontSide,
    });

    this.system = new THREE.Points(geom, material);
    scene.add(this.system);

    var blackGlobe = new THREE.Mesh(
      geom,
      /* new THREE.MeshStandardMaterial({
        color: new THREE.Color(this.sphereColor),
        metalness: 0.4,
        roughness: 0.58,
        emissive: new THREE.Color("black"),
        emissiveIntensity: 1,
        side: FrontSide,
        blending: THREE.AdditiveBlending,
      }) */
      new THREE.ShaderMaterial({
        uniforms: {
          color: { value :new THREE.Color(this.sphereColor)}
        },
        fragmentShader: shaders.fragmentShaderInnerGlobe,
        vertexShader: shaders.vertexShaderInnerGlobe,
        blending: THREE.AdditiveBlending,
        side: THREE.FrontSide
      })
    );
    blackGlobe.scale.setScalar(0.999);
    this.system.add(blackGlobe);
    var atmosphera = new THREE.Mesh(
      new THREE.SphereGeometry(1.2, 50, 50),
      new THREE.ShaderMaterial({
        uniforms: {
          color: { value: new THREE.Color(.3, .6, 1.) },
        },
        fragmentShader: shaders.fragmentShaderAtmosfera,
        vertexShader: shaders.vertexShaderInnerGlobe,
        blending: THREE.AdditiveBlending,
        side: THREE.BackSide,
      })
    );

    atmosphera.scale.setScalar(.98);
    this.system.add(atmosphera);

    this.renderer = renderer;
    this.scene = scene;
    this.camera = camera;
    this.object = this.system;

    let spotLight = new THREE.SpotLight(0xffffff, 1.);
    spotLight.position.set(-150, 100, 100);

    this.spotLight = spotLight;

    let ambLight = new THREE.AmbientLight(0xffffff, 0);
    ambLight.position.set(5, 3, 5);
    this.camera.add(spotLight);
    this.camera.add(ambLight);
    this.computeBoundingBox();

    for (let index = 0; index < this.pointsLines.length; index++) {
      const element = this.pointsLines[index];
      this.addPoints(element.x, element.y, element.x2, element.y2);
    }

 
    
  }

  randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  onMouseMove(event) {
    /* this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1; */
    this.mouse.x = (event.offsetX / this.width) * 2 - 1;
    this.mouse.y = -(event.offsetY / this.height) * 2 + 1;
    this.mouseEvent = event;
    
  }

  /**
   *
   * @param {THREE.Vector3} start
   * @param {THREE.Vector3} end
   * @param {THREE.Vector3} bezier
   * @returns
   */
  makeLine(start, end, scene) {
    const points = [];
    const countPoints = 60;
    for (let i = 0; i <= countPoints; i++) {
      const point = new THREE.Vector3().lerpVectors(
        start,
        end,
        i / countPoints
      );
      point.normalize();
      point.multiplyScalar(1 + 0.05 * Math.sin((Math.PI * i) / countPoints));
      points.push(point);
    }

    const path = new THREE.CatmullRomCurve3(points);

    const geometry = new THREE.BufferGeometry().setFromPoints(points);

    const material = new THREE.ShaderMaterial({
      transparent: true,
      side: THREE.FrontSide,
      vertexShader: shaders.vertexShaderLines,
      fragmentShader: shaders.fragmentShaderLines,
      uniforms: {
        time: { value: this.time },
        color: { value: new THREE.Vector3(0.086, 0.874, 0.921) },
        step: { value: 1 },
      },
    });

    // Create the final object to add to the scene
    const curveObject = new THREE.Line(geometry, material);
    curveObject.name = 'line'
    return curveObject;
  }

  computeBoundingBox() {
    let offset = 1.15 ;
    const boundingBox = new THREE.Box3();
    boundingBox.setFromObject(this.object);
    const center = boundingBox.getCenter();
    const size = boundingBox.getSize();

    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = this.camera.fov * (Math.PI / 180);

    this.cameraZ = maxDim / 2 / Math.tan(fov / 2);
    this.cameraZ *= offset;
    this.camera.position.z = center.z + this.cameraZ;
    const minZ = boundingBox.min.z;
    const cameraToFarEdge = minZ < 0 ? -minZ + this.cameraZ : this.cameraZ - minZ;

    this.camera.far = cameraToFarEdge * 3;
   
    this.camera.lookAt(this.object.position);
    this.camera.updateProjectionMatrix(); 

    let controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = false;
    controls.zoomSpeed = 0.1;
    controls.enableKeys = false;
    controls.screenSpacePanning = false;
    controls.enableRotate = true;
    controls.autoRotate = true;
    controls.dampingFactor = 1;
    controls.autoRotateSpeed = .7;
    controls.enablePan = false;

    controls.target.set(center.x, center.y, center.z);
    controls.update();
    this.controls = controls;
    this.renderer.setSize(this.width, this.height);
    this.container.appendChild(this.renderer.domElement);
    this.start();
  }

  start() {
    this.clicked = false;
    if (!this.frameId) {
      this.container.addEventListener(
        "mousemove",
        (event) => {
          this.onMouseMove(event);
        },
        false
      );
      this.frameId = requestAnimationFrame(this.animate);
    }
  }

  //Convert the positions from a lat, lon to a position on a sphere.
  latLongToVector3(lat, lon, radius, heigth) {
    var phi = (lat * Math.PI) / 180;
    var theta = ((lon - 180) * Math.PI) / 180;

    var x = -(radius + heigth) * Math.cos(phi) * Math.cos(theta);
    var y = (radius + heigth) * Math.sin(phi);
    var z = (radius + heigth) * Math.cos(phi) * Math.sin(theta);

    return new THREE.Vector3(x, y, z);
  }

  addPoints(x, y, x2, y2) {
    
    const v3_1 = this.latLongToVector3(x, y, 1, 0);
    const v3_2 = this.latLongToVector3(x2, y2, 1, 0);

    const d = Math.pow(
      Math.pow(v3_2.x - v3_1.x, 2) +
        Math.pow(v3_2.y - v3_1.y, 2) +
        Math.pow(v3_2.z - v3_1.z, 2),
      1 / 2
    );

    let bx = Math.abs((v3_1.x + v3_2.x) / 2);

    let by = Math.abs((v3_1.y + v3_2.y) / 2);

    let bz = Math.abs((v3_1.z + v3_2.z) / 2);

    const geometry = new THREE.SphereGeometry(0.008, 10, 10);
    const material = new THREE.MeshBasicMaterial({ color: 0x02bcd4 });
    const sphere = new THREE.Mesh(geometry, material);
    const sphere2 = new THREE.Mesh(geometry, material);
    const sphere3 = new THREE.Mesh(
      geometry,
      new THREE.MeshBasicMaterial({ color: "red" })
    );

    sphere.position.set(v3_1.x, v3_1.y, v3_1.z);
    sphere2.position.set(v3_2.x, v3_2.y, v3_2.z);

    this.system.add(sphere);
    this.system.add(sphere2);

    const line = this.makeLine(v3_1, v3_2);
    line.geometry.setDrawRange(0, 0);
    line.name = this.lines.length;
    this.lines.push(line);
    this.system.add(line)

    this.breakePointsControl(this.width);
  }

  animateLine() {
    
    for (let index = 0; index < this.lines.length; index++) {
      const line = this.lines[index];
      const tl = gsap
        .timeline()
        .to(
          this.timeLines,
          {
            duration: 4,
            value: 1,
            delay: Math.random(),
            ease: Power4.easeInOut,
            onUpdate:()=>{
                line.geometry.setDrawRange(0, Math.floor(line.geometry.attributes.position.count  * this.timeLines.value) )
             },
          },
          `+=${Math.random()}`
        )
        .to(
          this.timeLines,
          {
            duration: 4,
            value: 0,
            delay: Math.random(),
            ease: Power4.easeInOut,
            onUpdate:()=>{
              line.geometry.setDrawRange(line.geometry.attributes.position.count - Math.floor(line.geometry.attributes.position.count  * this.timeLines.value), line.geometry.attributes.position.count  * this.timeLines.value)
            }
          },
          `+=${Math.random()}`
        )
        
        .repeat(-1);

      this.gsapLines.push(tl);
    }
  }

  showDialogInfo() {
    if (this.INTERSECTED && this.state.showInfo) {
      return  <div
        style={{
          position: "absolute",
          left:  this.mouseEvent.offsetX - 250 ,
          top: this.mouseEvent.offsetY,
          zIndex: 10000,
          background: "rgba(25,28,58,.9)",
          padding: '15px',
          borderRadius: '10px',
          width: 250,
          color:  "rgba(32,188,211,1)",
          boxShadow: '1px 1px 25px rgb(2,187,211, .8)',
          textAlign: 'center'
        }}
      >
        <div className={'header'}>
        {this.pointsLines[this.INTERSECTED.name].info.header}
        </div>
        <div className={'body'}>
        {this.pointsLines[this.INTERSECTED.name].info.body}
        </div>
        
      </div>;
    } else {
      return <div />;
    }
  }

  renderScene() {
    if(this.width > 700){ //Desabilitandoel hover de las lineas en mobile
      this.raycaster.setFromCamera(this.mouse, this.camera);
    
      const intersects =  this.raycaster.intersectObjects(
        this.lines,
        false
      );
      if (intersects.length > 0 && intersects[0].object.geometry.drawRange.count > 50) {
       
  
        if (this.INTERSECTED != intersects[0].object) {
          
          if (this.INTERSECTED) {
            this.INTERSECTED.material.uniforms.color.value = this.INTERSECTED.material.uniforms.color.value;
            this.INTERSECTED.material.uniforms.time.value = this.INTERSECTED.material.uniforms.color.time;
          }
  
          this.INTERSECTED = intersects[0].object;
          this.INTERSECTED.material.uniforms.color.value = this.INTERSECTED.material.uniforms.color.value = new THREE.Color(
            0xb2ebf2
          );
          this.controls.autoRotate = false;
          document.getElementById("erw_three_planet").style.cursor = "pointer";
          this.time = this.INTERSECTED.material.uniforms.time.value;
          this.gsapLines[this.INTERSECTED.name].pause();
            this.setState({...this.state, showInfo: true})
          
        }
      } else {
        if (this.INTERSECTED) {
          this.INTERSECTED.material.uniforms.color.value = new THREE.Vector3(
            0.086,
            0.874,
            0.921
          );
          this.INTERSECTED.material.uniforms.time.value = this.time;
          this.gsapLines[this.INTERSECTED.name].resume();
          this.controls.autoRotate = true;
        }
        document.getElementById("erw_three_planet").style.cursor = "";
        this.INTERSECTED = null;
        this.time = 0;
        this.setState({...this.state, showInfo: false})
      }
    }
    

    this.renderer.render(this.scene, this.camera);
  }

  animate() {
    this.frameId = requestAnimationFrame(this.animate);
    this.controls.update();
    this.renderScene();
  }

  stop() {
    cancelAnimationFrame(this.frameId);
  }

  handleWindowResize() {
    this.width = this.container.clientWidth;
    this.height = this.container.clientHeight;
    this.camera.aspect = this.width / this.height;
    this.camera.lookAt(this.object.position);
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.width,this.height)
    this.breakePointsControl(this.width)
    //this.lines.forEach( l => l.scale.setScalar(.2))
  }

  breakePointsControl(width){
    if(width >= 1400){
      this.system.scale.setScalar(1) 
    }else if(width < 1400 && width >= 1200){
      this.system.scale.setScalar(.9) 
    }else if(width < 1200 && width >= 600){
      this.system.scale.setScalar(.8) 
    }else{
      this.system.scale.setScalar(1) 
    }
  }

  componentWillUnmount() {
    this.stop();
    this.destroyContext();
  }

  destroyContext() {
    this.container.removeChild(this.renderer.domElement);
    this.renderer.forceContextLoss();
    this.renderer.context = null;
    this.renderer.domElement = null;
    this.renderer = null;
  }

  render() {
    return (
      <div>
       {this.showDialogInfo()}
      </div>
    );
  }
}

export default Scene;
